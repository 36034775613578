<template>
  <el-dialog
      :title="'启用'"
      :close-on-click-modal="false"
      :visible.sync="visible"
      @cancel="reset()">
    <el-form :model="dataForm"  ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="所属项目" prop="parentId">
        <el-select v-model="dataForm.projectId" placeholder="请选择">
        <el-option
            v-for="item in dataList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="item.disabled!=='0'">
        </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "level-enable",
  data () {
    return {
      visible: false,
      dataForm: {
        id: 0,
        projectId: '',
      },
      dataList: [{
        id: '',
        name: '',
        disabled:''
      }],
    }
  },
  methods:{

    init(id){
      this.visible=true
      this.dataListLoading = true
      this.dataForm.id=id
      this.$http({
        url: this.$http.adornUrl('/levelManage/projectattributes/getProjectRootInfoForLevel'),
        method: 'get',
      }).then(({data}) => {
        if(data && data.code ===0)
        {
          this.dataList = data.data
        }
        this.dataListLoading = false
      })
    },
    dataFormSubmit(){
      this.$confirm(`启用该层级以及其父层级作为该项目的层级，是否还要继续进行？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/level/setEnabled?id='+this.dataForm.id+'&projectId='+this.dataForm.projectId),
          method: 'get',
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    reset(){
      this.dataForm= {
        id: 0,
            projectId: '',
      },
      this.dataList= [{
        id: '',
        name: '',
        disabled:''
      }]
      this.visible=false
    }
  },
  mounted() {
    this.init();
  }

}
</script>

<style scoped>

</style>